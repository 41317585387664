<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <span><strong>School Class Name:</strong></span>
          <p class="title is-5 mt-2">{{ schoolClass.name }}</p>
        </div>
        <div class="column is-12">
          <b-field label="Term">
            <b-select v-model="termId" placeholder="Select a term" expanded>
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.sessionTitle }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-12">
          <button
            type="submit"
            class="button btn-120 is-primary is-capitalized is-pulled-right"
            @click="processMasterList()"
          >
            Submit
          </button>
        </div>
        <div class="column is-12">
          <template v-if="loading">
            <loading>{{ resultProgress }}</loading>
          </template>
          <template v-else>
            <template v-if="result_path">
              <iframe :src="result_path" />
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'
import TERMS from '../../../graphql/term/Terms.gql'
import { fetchAppUrl } from '@/assets/js/app_info.js'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Master List',
      terms: [],
      termId: null,
      schoolClassId: null,
      schoolClasses: [],
      resultScores: [],
      loading: false,
      result_path: null,
      schoolClass: {},
      path: null,
      asyncJob: null,
      skipQuery: true,
      resultProgress: 'Fetching PDF...',
    }
  },
  apollo: {
    asyncJob: {
      query: gql`
        query asyncJobQuery($id: Int!) {
          asyncJob(id: $id) {
            jobId
            status
            progressCount
            result
          }
        }
      `,
      variables() {
        return { id: parseInt(this.async_job_id) }
      },
      skip() {
        return this.skipQuery
      },
      pollInterval: 500,
    },
    schoolClass: {
      query: gql`
        query schoolClassQuery($id: ID!) {
          schoolClass(id: $id) {
            id
            name
          }
        }
      `,
      variables() {
        return { id: parseInt(this.$route.params.id) }
      },
    },
  },
  watch: {
    asyncJob(data) {
      if (data.status === 'Done') {
        this.loading = false
        this.skipQuery = true
        this.result_path = data.result
      } else {
        this.loading = true
        this.resultProgress = data.status
      }
    },
  },
  methods: {
    processMasterList() {
      fetchAppUrl().then((path) => {
        this.loadPDF(path)
      })
    },
    loadPDF(path) {
      axios
        .get(`${path}/master_list`, {
          params: {
            school_class_id: this.schoolClassId,
            term_id: this.termId,
          },
        })
        .then((response) => {
          this.skipQuery = false
          this.async_job_id = response.data.async_job_id
        })
        .catch((error) => {
          // console.log(error.message);
        })
    },
  },
  mounted() {
    this.schoolId = this.$route.params.school_id
    this.schoolClassId = this.$route.params.id
    this.$store.commit('setPageTitle', this.pageTitle)

    this.$store.commit('setSubMenus', [
      {
        name: 'School Class Info',
        route: `/school/${this.schoolId}/school_class_info/${this.schoolClassId}`,
      },
    ])

    this.$apollo.addSmartQuery('terms', {
      query: TERMS,
      variables: {
        schoolId: parseInt(this.schoolId),
      },
    })

    this.$apollo.queries.terms.refetch()
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
